<template>
    <b-container v-if="mpcForm && mpcRows" class="mpc-form landscape" fluid>
        <b-row>
            <b-col>
                <b-btn variant="danger" class="mr-2" v-b-modal.deleteMpcForm>
                    Izbriši formu
                </b-btn>
                <router-link :to="$route.path + '/edit'">
                    <b-btn variant="primary">
                        Uredi formu
                    </b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row class="text-left my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-left">
                    <thead>
                    <tr>
                        <th>{{mpcForm.client.name}}</th>
                        <td><b>Broj računa: </b>{{mpcForm.invoice_number}}</td>
                    </tr>
                    <tr>
                        <td><b>Vlasnik: </b>{{mpcForm.client.owner.name}}</td>
                        <td><b>Datum računa: </b>{{mpcForm.invoice_date | moment("DD.MM.YYYY.")}}</td>
                    </tr>
                    <tr>
                        <td colspan=""><b>Grad: </b>{{mpcForm.client.township.name}}</td>
                        <td colspan=""><b>Adresa: </b>{{mpcForm.client.address}}</td>
                    </tr>
                    <tr>
                        <td colspan="">
                            <b>IDB: </b>{{mpcForm.client.id_number}};
                            <template v-if="mpcForm.client.pdv_number">
                                <b>PDV br.:</b> {{mpcForm.client.pdv_number}};
                            </template>
                        </td>
                        <td colspan=""><b>Datum obrasca: </b>{{mpcForm.form_date | moment("DD.MM.YYYY.")}}</td>

                    </tr>
                    <tr>
                        <td colspan=""><b>Dobavljač: </b>{{mpcForm.associate.name}}</td>
                        <td colspan="">
                            <b>
                                <span v-if="mpcForm.variant===1">
                                    Povratnica
                                </span>
                                <span v-else class="text-center">
                                    Prijemni list
                                </span>
                                / kalkulacija maloprodajne cijene br.: </b>
                            {{mpcForm.form_number}}
                        </td>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <roza-table :items="mpcRows"
                            :fields="mpcFormFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="4"
                            :colIndex="1"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="actions"
                            @action="action"
                            print-page
                            class="table-sm">
                    <template slot="custom-header">
                        <tr>
                            <th></th>
                            <th class="table-border-right" colspan="3"></th>
                            <th class="table-border-right" colspan="4">Nabavka u KM</th>
                            <th colspan="10">Maloprodaja u KM</th>
                        </tr>
                    </template>
                    <template slot="article" slot-scope="data">
                        {{data.item.bar_code}} <br/>
                        {{data.item.article}}
                    </template>
                    <template slot="tax">
                        <div v-if="mpcForm.tax_status">
                            17,0
                        </div>
                        <div v-else>
                            0,0
                        </div>
                    </template>
                </roza-table>

                <b-pagination align="right"
                              @input="getRows"
                              :total-rows="mpcRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>

        <b-row v-if="mpcForm.confirmed == 0" class="d-flex">
            <b-col md="12" class="text-right">
                <b-btn class="pull-right mx-2"
                       v-b-modal.modalConfirm
                       variant="success">
                    Potvrdi formu
                </b-btn>

                <b-btn class="pull-right"
                       v-b-modal.addRowModal
                       variant="primary">
                    Dodaj zapis
                </b-btn>
            </b-col>
        </b-row>

        <b-row v-if="currentPage === mpcRows.last_page">
            <b-col cols="12" md="4" offset-md="4">
                <b>SAČINIO:</b><br/>
                {{mpcForm.client.owner.name}}
            </b-col>
            <b-col cols="12" md="4">
                <b>ODOBRIO:</b><br/>
                {{mpcForm.client.owner.name}}
            </b-col>
        </b-row>

        <b-modal centered
                 id="modalConfirm"
                 title="Potvrda"
                 @ok="submit"
                 ok-title="Potvrdi">
            <p class="my-4">Jeste li sigurni da želite potvrditi formu?</p>
        </b-modal>

        <b-modal centered
                 ref="addRowModal"
                 id="addRowModal"
                 title="Dodaj zapis"
                 hide-footer>
            <b-form v-on:submit.prevent="addRow"
                    ref="addRowForm">
                <b-form-group
                        :label-cols-md="4"
                        label="Artikal:"
                        label-for="article">
                    <b-form-input id="article"
                                  name="article"
                                  size="sm"
                                  required
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Bar kod:"
                        label-for="bar_code">
                    <b-form-input id="bar_code"
                                  name="bar_code"
                                  size="sm"
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Mjerna jedinica:"
                        label-for="measuring_unit">
                    <b-form-input id="measuring_unit"
                                  name="measuring_unit"
                                  size="sm"
                                  required
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Količina:"
                        label-for="quantity">
                    <b-form-input id="quantity" name="quantity" required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Ukupna vrijednost bez PDV-a:"
                        label-for="taxless_value">
                    <b-form-input id="taxless_value"
                                  name="taxless_value"
                                  required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Nezavisni troškovi:"
                        label-for="rebate_value">
                    <b-form-input id="rebate_value" name="rebate_value" required
                                  type="number"
                                  step="0.001"
                                  size="sm"
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Maloprodajna cijena"
                        label-for="retail_price">
                    <b-form-input id="retail_price" name="retail_price" required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    />
                </b-form-group>
                <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
            </b-form>
        </b-modal>

        <b-modal centered
                 ref="deleteRow" title="Potvrda"
                 @ok="deleteRow(deleteRowId)"
                 @cancel="deleteRowId = 0"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati red?</p>
        </b-modal>

        <b-modal centered
                 id="deleteMpcForm"
                 title="Potvrda"
                 @ok="deleteMpcForm"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati Formu?</p>
        </b-modal>
    </b-container>

</template>

<script>
    export default {
        name: 'ShowMpcForm',
        data() {
            return {
                articles: null,
                mpcForm: null,
                mpcRows: null,
                mpcFormFields: [
                    {
                        key: 'position',
                        label: 'Broj'
                    },
                    {
                        key: 'article',
                        label: 'Bar kod i naziv'
                    },
                    {
                        key: 'measuring_unit',
                        label: 'Mj jed'
                    },
                    {
                        key: 'quantity',
                        label: 'Količina',
                        thClass: 'table-border-right',
                        class: 'table-border-right'
                    },
                    {
                        key: 'taxless_price',
                        label: 'Cijena bez PDV',
                        class: 'text-right'
                    },
                    {
                        key: 'taxless_value',
                        label: 'Vrijednost bez PDV',
                        class: 'text-right'
                    },
                    {
                        key: 'rebate_value',
                        label: 'Nezavisni troškovi',
                        class: 'text-right'
                    },
                    {
                        key: 'initial_value',
                        label: 'Nabavna vrijednost',
                        thClass: 'table-border-right',
                        class: 'table-border-right text-right'
                    },
                    {
                        key: 'diff_in_price_percentage',
                        label: '% razl. u cijeni',
                        class: 'text-right'
                    },
                    {
                        key: 'diff_in_price',
                        label: 'Razlika u cijeni',
                        class: 'text-right'
                    },
                    {
                        key: 'diff_in_value',
                        label: 'Razlika u vrijednosti',
                        class: 'text-right'
                    },
                    {
                        key: 'taxless_retail_price',
                        label: 'Maloprodajna cijena bez PDV',
                        class: 'text-right'
                    },
                    {
                        key: 'taxless_retail_value',
                        label: 'Maloprodajna vrijednost bez PDV',
                        thClass: 'table-border-right',
                        class: 'table-border-right text-right'
                    },
                    {
                        key: 'tax',
                        label: 'Porez(%)',
                        class: 'text-right'
                    },
                    {
                        key: 'retail_tax',
                        label: 'Maloprodajni PDV u Cijeni',
                        class: 'text-right'
                    },
                    {
                        key: 'retail_tax_value',
                        label: 'Maloprodajna vrijednost PDV',
                        class: 'text-right'
                    },
                    {
                        key: 'retail_value',
                        label: 'Maloprodajna vrijednost',
                        class: 'text-right'
                    },
                    {
                        key: 'retail_price',
                        label: 'Maloprodajna cijena',
                        class: 'text-right'
                    }
                ],
                prenosFields: [
                    '',
                    'taxlessValueSum',
                    'rebateValueSum',
                    {
                        key: 'initialValueSum',
                        class: 'table-border-right'
                    },
                    {
                        colspan: 2,
                    },
                    'diffInValueSum',
                    '',
                    {
                        key: 'taxlessRetailValue',
                        class: 'text-right table-border-right'
                    },
                    {
                        colspan: 2,
                    },
                    'retailTaxValue',
                    'retailValue',
                    ''
                ],
                actions: {
                    delete: {
                        name: 'Izbriši',
                        icon: 'fa fa-trash'
                    }
                },
                deleteRowId: 0,
                currentPage: 1,
                perPage: 10
            }
        },
        methods: {
            submit() {
                axiOsta.post(api.mpcFormConfirm, {
                    confirmed: this.mpcForm.id
                }).then(response => {
                    if (response.status === 200) {
                        this.$router.go();
                    }
                })
            },
            getRows() {
                axiOsta.get(api.mpcForms + this.$route.params.id + api.rows, {
                    params: {
                        page: this.currentPage,
                        perPage:this.perPage
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.mpcRows = response.data;
                    }
                })
            },
            addRow: function(e) {
                const formData = new FormData(e.target);
                formData.append('mpc_form_id', this.mpcForm.id);
                axiOsta.post(api.mpcRows, formData).then(response => {
                    if (response.status === 201) {
                        this.getRows();
                        this.$refs.addRowModal.hide();
                        this.$refs.addRowForm.reset();
                    }
                });
            },
            deleteRow(id) {
                axiOsta.delete(api.mpcRows + id).then(response => {
                    if (response.status === 200) {
                        this.getRows();
                    }
                });
            },
            deleteModal(id) {
                this.deleteRowId = id;
                this.$refs.deleteRow.show();
            },
            deleteMpcForm() {
                axiOsta.delete(api.mpcForms + this.mpcForm.id).
                then(response => {
                    this.$router.replace('/mpc_forms');
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'delete':
                        this.deleteModal(item.id);
                        break;
                }
            }
        },
        created() {
            this.getRows();
            axiOsta.get(api.mpcForms + this.$route.params.id).
            then(response => {
                if (response.status === 200) {
                    this.mpcForm = response.data;
                }
            });
        }
    }
</script>
